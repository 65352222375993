<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <base-cropper
          :model="optionsLocal"
          @cropped-image="optionsLocal.logo"
        />
        <!--/ avatar -->
      </b-media-aside>

    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer
      ref="accountRules"
      v-slot="{ invalid }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit()"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Nombre de usuario"
              label-for="account-username"
            >
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nombre"
                  name="username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="optionsLocal.email"
                  :state="errors.length > 0 ? false:null"
                  name="Email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="optionsLocal"
            sm="6"
          >
            <b-form-group
              label="Número celular para iniciar sesión"
              label-for="phone"
            >
              <b-form-input
                v-model="phoneNumber"
                name="phone"
                placeholder="5555555555"
              />

            </b-form-group>
          </b-col>
          <b-col
            v-if="optionsLocal.customer"
            sm="6"
          >
            <div v-show="keepOldAddress">
              <p class="d-flex flex-column">
                <span class="text-muted">
                  ¿La dirección de tu cuenta es correcta? Si no, buscala abajo
                </span>
                <span v-if="optionsLocal.customer.address">
                  {{ optionsLocal.customer.address.street }} {{ optionsLocal.customer.address.ext_number }},
                  CP {{ optionsLocal.customer.address.postal_code }}, {{ optionsLocal.customer.address.city }},
                  {{ optionsLocal.customer.address.state }}, {{ optionsLocal.customer.address.country }}
                </span>
              </p>
              <b-button class="mb-3" @click="changeAddress()">
                Modificar dirección
              </b-button>
            </div>

            <div v-show="!keepOldAddress">
              <address-form-model
                info-type
                @getAddress="setAddressForEdit($event)"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <b-button
              v-if="customerName != optionsLocal.name || customerEmail != optionsLocal.email || (optionsLocal.phoneNumber && phoneNumber != optionsLocal.phone_number.substr(2,optionsLocal.phone_number.length)) || !keepOldAddress"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mt-2 mr-1"
              type="submit"
              :disabled="invalid"
            >
              Guardar cambios
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-warning"
              type="reset"
              class="mt-2"
              :to="{ name: 'home' }"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  // BCardText,
  BMedia,
  BMediaAside,
  // BMediaBody,
  // BLink,
  // BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mapActions, mapMutations } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import BaseCropper from '@/@core/components/BaseCropper.vue'
import AddressFormModel from '@/@core/components/AddressFormModel.vue'

export default {
  components: {
    AddressFormModel,
    BButton,
    BForm,
    // BImg,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    // BCardText,
    BMedia,
    BMediaAside,
    // BMediaBody,
    // BLink,
    ValidationProvider,
    ValidationObserver,
    BaseCropper,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      required,
      email,
      phoneNumber: null,
      customerName: null,
      customerEmail: null,
      keepOldAddress: true,
      user: {
              name: null,
              email: null,
              logo: null,
              customer_attributes: {
                id: null,
                address_attributes: null,
              },
            },
    }
  },
  beforeMount() {
    this.phoneNumber = this.optionsLocal.phone_number ? this.optionsLocal.phone_number.substr(2, this.optionsLocal.phone_number.length) : null
    this.customerName = this.optionsLocal.name
    this.customerEmail = this.optionsLocal.email
  },
  methods: {
    ...mapActions('users', ['editUser']),
    ...mapMutations('users', ['updateUserData']),
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    setAddressForEdit(x) {
      this.address_attributes = x
    },
    changeAddress() {
      this.keepOldAddress = !this.keepOldAddress
    },
    handleSubmit() {
      this.$refs.accountRules.validate().then(success => {
        if (success) {
          this.user.name = this.optionsLocal.name
          this.user.email = this.optionsLocal.email
          this.user.logo = this.optionsLocal.logo
          this.user.customer_attributes.id = this.optionsLocal.customer.id
          
          if (this.phoneNumber != this.optionsLocal.phone_number.substr(2, this.optionsLocal.phone_number.length)) {
            this.user.phone_number = `${52}${this.optionsLocal.phone_number}`
            this.user.cel_number = this.optionsLocal.phone_number ? `${52}${this.optionsLocal.phone_number}` : null
            }
          if (!this.keepOldAddress) {
            this.address_attributes.id = `${this.optionsLocal.customer.address.id}`
            this.user.customer_attributes.address_attributes = this.address_attributes
          } else {
            this.user.customer_attributes.address_attributes = this.optionsLocal.customer.address
          }

          this.editUser({
            id: this.optionsLocal.id,
            user: this.user,
          })
            .then(response => {
              this.updateUserData(response)
              this.$swal({
                title: 'Yeah!',
                text: 'Usuario editado!',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
          this.$router.push({ name: 'home' })
        } else {
          this.$swal({
            title: 'Oops! 😣',
            text: 'Algo salio mal, intenta de nuevo.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
